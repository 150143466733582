import { dom, library } from '@fortawesome/fontawesome-svg-core';

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faRss } from '@fortawesome/free-solid-svg-icons/faRss';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";

library.add(faFacebook, faTwitter, faInstagram, faYoutube, faRss, faSearch, faEnvelope);

dom.i2svg();
