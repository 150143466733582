import 'flickity';
import 'fslightbox';

import './modules/Icons';
import SideNavigation from './modules/SideNavigation';

SideNavigation.init();

setTimeout(function () {
    const slider = document.querySelector('.js-flickity');
    if (slider) {
        slider.classList.add('flickity-loaded');
    }
}, 50);
