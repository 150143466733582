class SideNavigation {
    constructor() {
        this.container = document.querySelector('.js-observe-side-navigation');
        this.elements = [];
        this.visibleElements = [];
        this.activeElement = null;
        this.observer = {};
    }

    init() {
        if (!this.container) {
            return;
        }

        this.observer = this.createObserver();

        this.elements = Array.from(document.querySelectorAll('[data-observed-by]'));

        this.elements.forEach(element => {
           this.observer.observe(element);
        });
    }

    createObserver() {
        const options = {
            root: null,
            threshold: 0.75
        };

        return new IntersectionObserver(this.checkActiveNavigationItem.bind(this), options);
    }

    checkActiveNavigationItem(observerEntries) {
        observerEntries.forEach(entry => {
            this.elements.forEach(element => {
               if(entry.target.id === element.id) {
                   element.visible = entry.isIntersecting;
               }
            });
        });

        for (let i = 0; i < this.elements.length; i++) {
            if (this.elements[i].visible) {
                if (this.activeElement) {
                    this.activeElement.classList.remove('is-active');
                }

                this.activeElement = document.querySelector(`[href="#${this.elements[i].id}"]`);
                this.activeElement.classList.add('is-active');

                break;
            }
        }
    }
}

export default new SideNavigation();
